import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { replaceLinks, UsedLinks } from '../helpers.ts'
import { TypingMessageQueue } from '../TypingMessageQueue.ts'
import { EventSourceMessage, useEventSource } from '../useEventSource.ts'
import GlobalContext from './GlobalContext.tsx'
import MyMarkdown from './Markdown.tsx'
const host = window.location.hostname.includes('localhost')
  ? 'http://localhost:3000'
  : 'https://zuericitygpt-api.gpt.liip.ch'

const EVENT_SOURCE_NAME = 'model'

export const ResultModel = (props: {
  modelId: string
  query: string | null
  userId: string | null
  disabled?: boolean
}) => {
  const [sseUri, setSseUri] = useState('')
  const { t } = useTranslation()
  const [functionResult, setFunctionResult] = useState<string>('')
  const { mainLanguage, org } = useContext(GlobalContext)
  const { close: eventSourceClose, start: eventSourceStart } = useEventSource()
  const [usedLinksResult, setUsedLinksResult] = useState<UsedLinks[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (props.query && !(props.disabled === true)) {
      setSseUri(
        `${host}/${org}/assistant/sse?query=${encodeURIComponent(`${props.query} mode:mixtral`)}&modelId=${
          props.modelId
        }&key=X9hL4Gp5W2D7eRtF&r=${Math.random()}${props.userId ? `&uID=${props.userId}` : ''}&lang=${mainLanguage}`,
      )
      setUsedLinksResult([])
      setFunctionResult('')
    }
  }, [mainLanguage, org, props.modelId, props.query, props.userId, props.disabled])

  const parseMessageFromEventSource = useCallback((parsedData: Record<string, any>, queue: TypingMessageQueue) => {
    try {
      if (parsedData.usedLinks) {
        setUsedLinksResult(linksResult => {
          return linksResult.concat(parsedData.usedLinks)
        })
      }
      if (parsedData.tps) {
        queue.enqueue(`

----------------

Performance: ${parsedData.tps} tokens per second 
`)

        return
      }
      if (parsedData.response) {
        if (parsedData.response === '__THIS_IS_THE_ANSWER_END__') {
          queue.setThisIsTheEnd()
          setIsLoading(false)

          return
        }

        if (parsedData.response === '__THIS_IS_THE_END__') {
          eventSourceClose(EVENT_SOURCE_NAME)
          queue.enqueue(parsedData.response)
          queue.setThisIsTheEnd()
          return
        }

        if (parsedData.response === '__CLR__') {
          queue.enqueue(parsedData.response)

          return
        }

        queue.enqueue(parsedData.response)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    let linksReceived = false
    try {
      if (props.disabled === true) {
        return
      }
      if (!sseUri) {
        return
      }
      const queue = new TypingMessageQueue(setFunctionResult)
      const onerror = (e: Event) => {
        if (!linksReceived) {
          setFunctionResult(queryResult =>
            queryResult.concat(`

${t('answers.serverConnectionError')}`),
          )
        }
        console.log('SSE Error', e)
        eventSourceClose(EVENT_SOURCE_NAME)
      }
      const onmessage = (event: EventSourceMessage) => {
        const parsedData = JSON.parse(event.data)

        if (parsedData.links) {
          linksReceived = true
        }
        parseMessageFromEventSource(parsedData, queue)
      }
      setIsLoading(true)
      setFunctionResult(t('answers.oneMoment'))

      eventSourceStart(EVENT_SOURCE_NAME, sseUri, onmessage, onerror)

      return () => {
        eventSourceClose(EVENT_SOURCE_NAME)
      }
    } catch (error) {
      console.log(error)
    }
  }, [eventSourceClose, eventSourceStart, parseMessageFromEventSource, props.disabled, sseUri, t])
  if (!functionResult) {
    return null
  }
  let title = ''
  switch (props.modelId) {
    case 'mistral':
      title = 'Mistral AI API - mistral-small (Mixtral-8x7B)'
      break
    case 'mistral-medium':
      title = 'Mistral AI API - mistral-medium'
      break
    case 'runpod':
      title = '"Self-hosted" Mixtral-8x7b (3.5bpw) on a RTX 3090'
      break
    case '4-turbo-16k':
      title = 'OpenAI / GPT 4-turbo'
      break
  }

  return (
    <div>
      <h1>{title}</h1>

      <div className="resultBox" translate="no">
        <MyMarkdown
          markdown={
            replaceLinks(functionResult, usedLinksResult) +
            (isLoading
              ? ' ![](https://chregus.rokka.io/dynamic/crop-width-200-height-25--resize-width-20/o-af-1/b9a927/circles-menu-1.gif)'
              : '')
          }
          usedLinks={usedLinksResult}
        ></MyMarkdown>
      </div>
    </div>
  )
}
